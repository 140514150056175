<template>
  <div>
    <v-sheet
      v-if=' loading'

      :color='`grey`'
      class='pa-3'
    >
      <v-skeleton-loader
        class='mx-auto'
        type='text-field, table, data-table, selection'
      >
      </v-skeleton-loader>
    </v-sheet>

    <v-card v-else>
      <snack-view
        :message='snackbar.message'
        :activate='snackbar.active'
        :bg-color='snackbar.color'
      ></snack-view>
      <div>
        <v-card-title class='font-weight-bold'>
          Liste des clients
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols='12'
              md='8'
              sm='12'
              xl='6'
            >
              <v-row>
                <v-col
                  cols='12'
                  sm='12'
                  md='4'
                  lg='4'
                  xl='4'
                >
                  <v-select
                    v-model='selectedContactGlobal'
                    :rules='LocalNameRules'
                    label='Type de contrat'
                    :items='typcontrat'
                    item-text='name'
                    item-value='id'
                    outlined
                    dense
                    hide-details='auto'
                    @change='contractChange()'
                  >
                  </v-select>
                </v-col>

                <v-col
                  cols='12'
                  sm='12'
                  md='4'
                  lg='4'
                  xl='4'
                >
                  <v-select
                    v-model='selectedSegmentGlobal'
                    :rules='LocalNameRules'
                    label='Segment'
                    :items='segment'
                    item-text='name'
                    item-value='id'
                    outlined
                    dense
                    hide-details='auto'
                    @change='segmentChange()'
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols='12'
              sm='12'
              md='4'
              lg='4'
              xl='4'
            >
              <v-text-field
                v-model='search'
                :append-icon='icons.mdiMagnify'
                label='Rechercher'
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers='headers'
          :items='clients'
          :search='search'
          :options='dataOptions'
          :items-per-page='15'
          sort-by='id'
          class='table-kitchen-sink'
        >
          <!-- product -->
          <template #[`item.num`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.id }}</span>
              </div>
            </div>
          </template>

          <template #[`item.code`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.code }}</span>
              </div>
            </div>
          </template>

          <template #[`item.client`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.name }}</span>
                <span class='d-block text--primary'>{{ item.num_dist }}</span>
              </div>
            </div>
          </template>

          <template #[`item.email`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.email }}</span>
                <span class='d-block text--primary'>{{ item.tel }}</span>
              </div>
            </div>
          </template>

          <template #[`item.contrat`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.customer_segments.length
                  }}</span>
              </div>
            </div>
          </template>

          <template #[`item.local`]='{item}'>
            <div class='d-flex align-center'>
              <div class='d-flex flex-column '>
                <span class='d-block font-weight-semibold text-truncate text--primary'>{{ item.local }}</span>
                <span
                  class='d-block font-weight-semibold text-truncate text--primary'>{{ item.customer_segments.map(obj => obj.local_count).reduce((accumulator, current) => accumulator + current, 0)
                  }}</span>
              </div>
            </div>
          </template>

          <template #[`item.actions`]='{ item }'>
            <div class='col-12 w-'>
              <div v-if='isRoleRoot()'>
                <v-btn
                  outlined
                  x-small
                  color='#F06529'
                  class='ml-1 mt-1'
                  @click='openDialog(item)'
                >
                  <v-icon
                    x-small
                    class='me-1'
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  Visualiser les Contrats
                </v-btn>
                <v-btn
                  outlined
                  x-small
                  color='#3a44ff'
                  class='ml-1 mt-1'
                  @click='showUsers(item)'
                >
                  <v-icon
                    x-small
                    class='me-1'
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  Liste des utilisateurs
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  outlined
                  x-small
                  color='#F06529'
                  class='ml-1 mt-1'
                  @click=''
                >
                  <v-icon
                    x-small
                    class='me-1'
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  Visualiser les locaux
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>

        <v-dialog
          v-model='dialog_contrat'
          max-width='500px'
        >
          <v-card>
            <v-card-title>
              <span>Visualisation des contrats</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <span>Liste des contrats du client {{ selectedLine.name }}</span>

              <v-container>
                <v-row>
                  <v-col
                    cols='12'
                  >
                    <template>
                      <v-data-table
                        :headers='headersDetails'
                        :items='details'
                        :items-per-page='15'
                        sort-by='id'
                        class='table-kitchen-sink'
                      >
                        <template #[`item.num`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='d-flex flex-column ms-3'>
                              <span
                                class='d-block font-weight-bold text-truncate primary--text'>{{ details.indexOf(item) + 1
                                }}</span>
                            </div>
                          </div>
                        </template>

                        <template #[`item.contrat`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='d-flex flex-column ms-3'>
                              <span class='d-block font-weight-bold text-truncate primary--text'>{{ item.contrat
                                }}</span>
                            </div>
                          </div>
                        </template>

                        <template #[`item.type`]='{item}'>
                          <div class='d-flex align-center'>
                            <div class='d-flex flex-column ms-3'>
                              <span class='d-block font-weight-bold text-truncate primary--text'>{{ item.type }}</span>
                            </div>
                          </div>
                        </template>
                      </v-data-table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color='primary'
                outlined

                @click='close'
              >
                Annuler
              </v-btn>
              <v-btn
                color='primary'
                @click='close'
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref }       from '@vue/composition-api'
import {
  mdiMagnify,
  mdiEyeOutline,
}                    from '@mdi/js'
import data          from '@/views/locaux/datatable'
import Http          from '@/helpers/http'
import { useRouter } from '@core/utils'
import Vue           from 'vue'
import moment        from 'moment'

import SnackView                              from '@/views/SnackView'
import router                                 from '@/router'
import { isRoleRoot, isRoleAdminGetionnaire } from '@/helpers/constants/roles'

Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
  },
  setup() {
    const search = ref('')
    // eslint-disable-next-line no-shadow
    const { router } = useRouter()
    const clients = ref([])
    const loading = true
    const details = []

    const goToListLocaux = client => {
      // eslint-disable-next-line no-shadow,radix
      // const clientId = parseInt(client.id)
      // eslint-disable-next-line no-use-before-define
      router.push({ name: 'list-emplacement', params: { id: '1', data: [] } })
    }

    // const dialog = ref(false)

    const localForm = ref(null)

    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })

    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const orderNum = item => clients.value.indexOf(item) + 1
    const selectedLine = ref([])

    const segment = ref([])
    const typcontrat = ref([])

    const selectedSegment = ref([])
    // eslint-disable-next-line camelcase
    const corporate_segment = ref([])

    const loadClients = () => {
      const param = {}

      /* if (this.selectedSegmentGlobal!=undefined) {
        param.corporateSegment = this.selectedSegmentGlobal
      }
      if (this.selectedContactGlobal !=undefined) {
        param.customerType = this.selectedContactGlobal
      } */

      // Http.get('customer')
      Http.post('get-customer-filter', param)
        .then(async response => {
          if (response.status === 200) {
            clients.value = await response.data
            console.log('clients0')
            console.log(clients.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const loadSegments = () => {
      Http.get('segment')
        .then(async response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-use-before-define
            segment.value = response.data
            segment.value.push({
              id: -1,
              name: 'Tous',
              created_at: '2022-06-14T11:07:50.000000Z',
              updated_at: '2022-06-23T10:54:42.000000Z',
            })
            console.log('segments')
            // eslint-disable-next-line no-use-before-define
            console.log(segment.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }
    const loadTypeContrat = () => {
      Http.get('customer-type')
        .then(async response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-use-before-define
            typcontrat.value = response.data
            typcontrat.value.push({
              id: -1,
              name: 'Tous',
              created_at: '2022-06-14T11:07:50.000000Z',
              updated_at: '2022-06-23T10:54:42.000000Z',
            })
            console.log('typcontrats')
            // eslint-disable-next-line no-use-before-define
            console.log(typcontrat.value)
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    }

    return {
      icons: {
        mdiMagnify,
        mdiEyeOutline,
      },
      search,
      loadClients,
      loadSegments,
      loadTypeContrat,
      LocalNameRules,
      orderNum,
      segment,
      typcontrat,
      details,
      dialog_contrat: false,
      selectedSegment,
      corporate_segment,
      selectedContrat: '',
      selectedContactGlobal: null,
      selectedSegmentGlobal: null,
      selectedLine,

      // goToListLocaux,
      localForm,
      headers: [
        { text: 'N°', value: 'num', divider: true },
        { text: 'Code', value: 'code', divider: true },
        { text: 'Client', value: 'client', divider: true },
        { text: 'Email/Téléphone', value: 'email', divider: true },
        { text: 'Contrat', value: 'contrat', divider: true },
        { text: 'Locaux', value: 'local', divider: true },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],

      headersDetails: [
        {
          text: 'N°', align: 'start', sortable: false, value: 'num', divider: true,
        },
        { text: 'Contrat', value: 'contrat', divider: true },
        {
          text: 'Type', value: 'type', divider: true, align: 'center',
        },
      ],

      dataOptions: {
        sortBy: ['id'],
      },
      clients,
      loading,
      editedIndex: -1,
      defaultItem: {},
      snackbar,
      valid: true,
    }
  },
  mounted() {
    this.loadClients1()

    // this.loadClients()
    this.loadSegments()
    this.loadTypeContrat()
  },
  methods: {
    isRoleRoot,
    isRoleAdminGetionnaire,

    close() {
      this.dialog_contrat = false
    },
    openDialog(item) {
      this.selectedLine = item

      // {
      //   num: '1',
      //   contrat: 'SME',
      //   type: 'postpayé ',
      // },
      const { customer_segments } = item
      const listDetails = ref([])

      for (let i = 0; i < customer_segments.length; i++) {
        console.log(customer_segments[i])
        listDetails.value.push(
          {
            contrat: customer_segments[i].categorie.name,
            type: customer_segments[i].customer_type.name,
          },
        )
      }
      console.log(listDetails.value)
      this.details = listDetails.value
      console.log(this.details)
      this.dialog_contrat = true
    },
    initialize() {
      this.clientList = JSON.parse(JSON.stringify(data))
    },

    // openDialog(item) {
    //   console.log('item', item.customer_segments)
    //   this.selectedSegment = item.customer_segments
    //   this.dialog = true
    // },
    showUsers(item) {
      console.log('item', item.id)
      router.push({ name: 'list-user', params: { id: item.id, data: [] } })
    },

    goToListLocaux() {
      // const a = []
      Http.get(`get-locals/${this.selectedContrat}`)
        .then(async response => {
          if (response.status === 200) {
            this.locals = response.data
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
      if (this.$refs.localForm.validate()) {
        console.log('formulaire validé')
        console.log(this.selectedContrat)
        this.dialog = false

        router.push({ name: 'list-emplacement', params: { id: this.selectedContrat, data: [] } })
      }
    },
    contractChange() {
      this.loadClients1(this.selectedSegmentGlobal, this.selectedContactGlobal)
    },
    segmentChange() {
      this.loadClients1(this.selectedSegmentGlobal, this.selectedContactGlobal)
    },

    loadClients1(segment, contrat) {
      this.loading = true

      const param = {}
      if (segment !== undefined && segment != -1) {
        param.corporateSegment = segment
      }
      if (contrat != undefined && contrat != -1) {
        param.customerType = contrat
      }
      Http.post('get-customer-filter', param)
        .then(async response => {
          if (response.status === 200) {
            this.loading = false
            this.clients = await response.data
            console.log('clients0')
            console.log(this.clients.value)
          }
        })
        .catch(error => {
          this.loading = false
          console.error('Erreur :', error)
        })
    },
  },
}
</script>

<style lang='scss' scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

</style>
