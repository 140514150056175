<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :color="snackbar.color"
    :value="activate"
    top
    right
    transition="scroll-x-reverse-transition"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

export default {
  name: 'SnackView',
  props: {
    timeout: {
      type: Number,
    },
    bgColor: {
      type: String,
    },
    message: {
      type: String,
      required: true,
    },
    activate: {
      required: true,
    },
  },
  setup(props) {
    const snackbar = ref({
      timeout: (props.timeout === undefined) ? 5000 : props.timeout,
      color: (props.bgColor === undefined) ? 'success' : props.bgColor,
      message: props.message,
      activate: props.activate,
    })

    const autoResetActivation = () => {
      setTimeout(() => {
        snackbar.value.activate = false
      }, snackbar.value.timeout)
    }

    onMounted(() => {
      autoResetActivation()
    })

    return {
      snackbar,
      autoResetActivation,
    }
  },
}
</script>

<style scoped>

</style>
