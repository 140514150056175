import { CATEGORIE_FLOTTE } from '@/helpers/constants/contrat'

export const ROLE_ROOT = 13
export const ROLE_ADMIN_GESTIONNAIRE = 1
export const ROLE_GESTIONNAIRE = 9
export function isRoleRoot() {
  return JSON.parse(localStorage.getItem('defaultRole')).id === ROLE_ROOT
}

export function isRoleAdminGetionnaire() {
  return JSON.parse(localStorage.getItem('defaultRole')).id === ROLE_ADMIN_GESTIONNAIRE
}

export function isRoleGetionnaire() {
  return JSON.parse(localStorage.getItem('defaultRole')).id === ROLE_GESTIONNAIRE
}
export function isVisu() {
  const visu = localStorage.getItem('visu') ? localStorage.getItem('visu') : 0

  return visu != 1
}
